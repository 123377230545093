<template>
  <div class="main">
    <div class="candidate-CourseList content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Course</a-breadcrumb-item>
        <a-breadcrumb-item> Sub Course </a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="title" v-if="course"><h4>{{course.title}}</h4></div>
          <div class="add">
            <a-button type="primary">
              <router-link to="/candidate/course"><ArrowLeftOutlined />  Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <div class="body">
        <h4>Courses Offered</h4>
        <ListCourse :courseId="courseId" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import ICourse from '../../../interface/course'
import courseService from '../../../services/course'

import ListCourse from '@/components/candidate/course/ListCourse.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListCourse,
    ArrowLeftOutlined
  },
  setup () {
    const route = useRoute()
    const course = ref<ICourse>()
    const courseId = ref<string>(route.params.id as string)
    const getParentCourseDetails = async () => {
      const responce = await courseService.getCourseDetails(courseId.value)
      course.value = responce.data
    }
    onMounted(() => {
      getParentCourseDetails()
    })
    return {
      courseId,
      course
    }
  }
})
</script>

<style lang="scss">
.candidate-CourseList {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    .title {
      float: left;
      font-family: "TT Norms Pro Medium";
      h4 {
        color: #646464;
        font-family: "TT Norms Pro Medium";
        font-size: 16px;
        text-align: left;
        margin: 0;
      }
    }
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Regular";
      font-size: 16px;
      text-align: left;
      margin: 0px 0 15px 0;
      text-transform: capitalize;
    }
  }
}
@media only screen and (max-width: 450px) {
  .candidate-CourseList {
    .body .courses-list .course-details .gutter-box .chapters {
      flex-direction: column;
      margin: 10px 0 0 0;
    }
  }
}
</style>
